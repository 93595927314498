import "styles/pages/page-online.scss";

import React, { useState } from "react";
import { Link } from "gatsby";

import Layout from "components/Layout";
import Seo from "components/Seo";
import Divider from "components/Divider";
import SubpageHeader from "components/SubpageHeader";
import ContactTile from "components/ContactTile";
import Breadcrumps from "components/Breadcrumps";
import BookingModal from "../components/BookingModal";

const OnlinePage = ({ pageContext }) => {
  const seo = pageContext.seo;
  const pageData = pageContext.data.pageOnlineTherapy;
  const [bookingModal, setBookingModal] = useState(false);

  return (
    <Layout>
      <Seo title={seo.title} description={seo.metaDesc} />
      <Breadcrumps data={pageContext.data.title} />
      <SubpageHeader title={pageContext.data.title} />
      <section className="online-section-1">
        <div className="container">
          <h2 className="section-title">{pageData.section1Title}</h2>
          <Divider marginTop={15} marginBottom={50} />
          <h3>{pageData.section1Text}</h3>
          <div className="online-section-1__points-container">
            <div className="row">
              <div className="col-lg-3">
                <div className="online-section-1__point-container">
                  <div className="online-section-1__circle online-section-1__circle--1">
                    1
                  </div>
                  <div
                    className="online-section-1__point-text"
                    dangerouslySetInnerHTML={{ __html: pageData.section1First }}
                  />
                  <div
                    className="online-section-1__small-text"
                    dangerouslySetInnerHTML={{
                      __html: pageData.section1FirstNote,
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-3">
                <div className="online-section-1__point-container">
                  <div className="online-section-1__circle online-section-1__circle--2">
                    2
                  </div>
                  <div
                    className="online-section-1__point-text"
                    dangerouslySetInnerHTML={{
                      __html: pageData.section1Second,
                    }}
                  />
                  <div
                    className="online-section-1__small-text"
                    dangerouslySetInnerHTML={{
                      __html: pageData.section1SecondNote,
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-3">
                <div className="online-section-1__point-container">
                  <div className="online-section-1__circle online-section-1__circle--3">
                    3
                  </div>
                  <div
                    className="online-section-1__point-text"
                    dangerouslySetInnerHTML={{ __html: pageData.section1Third }}
                  />
                  <div
                    className="online-section-1__small-text"
                    dangerouslySetInnerHTML={{
                      __html: pageData.section1ThirdNote,
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-3">
                <div className="online-section-1__point-container">
                  <div className="online-section-1__circle online-section-1__circle--4">
                    4
                  </div>
                  <div
                    className="online-section-1__point-text"
                    dangerouslySetInnerHTML={{
                      __html: pageData.section1Fourth,
                    }}
                  />
                  <div
                    className="online-section-1__small-text"
                    dangerouslySetInnerHTML={{
                      __html: pageData.section1FourthNote,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="online-section-1__bottom-text"
            dangerouslySetInnerHTML={{ __html: pageData.section1BottomText }}
          />
        </div>
      </section>
      <section className="online-section-2">
        <div className="container">
          <h2 className="section-title">{pageData.section2Title}</h2>
          <Divider marginBottom={45} marginTop={15} />
          <div className="row">
            <div className="col-md-6">
              <div
                className="online-section-2__text"
                dangerouslySetInnerHTML={{ __html: pageData.section2Text }}
              />
            </div>
            <div className="col-md-6">
              <img
                src={pageData.section2Img?.sourceUrl}
                alt={pageData.section2Img?.altText}
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="online-section-3">
        <div className="container">
          <h2 className="section-title">{pageData.section3Title}</h2>
          <Divider marginBottom={45} marginTop={15} />
          <div
            className="online-section-3__text"
            dangerouslySetInnerHTML={{ __html: pageData.section3Text }}
          />
          <h3 className="online-section-3__subtitle">
            {pageData.section3Heading}
          </h3>
          <div className="row">
            <div className="col-3">
              <div className="online-section-3__icons-container">
                <a
                  href="/"
                  target="_blank"
                  rel="noreferrer nofollow"
                  className="online-section-3__single-icon"
                >
                  <img src={require("assets/icons/skype.svg").default} alt="" />
                </a>
                <a
                  href="https://www.facebook.com/spokojwglowie"
                  target="_blank"
                  rel="noreferrer nofollow"
                  className="online-section-3__single-icon"
                >
                  <img
                    src={require("assets/icons/messenger.svg").default}
                    alt=""
                  />
                </a>
                <a
                  href="/"
                  target="_blank"
                  rel="noreferrer nofollow"
                  className="online-section-3__single-icon"
                >
                  <img
                    src={require("assets/icons/whatsapp.svg").default}
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="col-9">
              <div
                className="online-section-3__text"
                dangerouslySetInnerHTML={{
                  __html: pageData.section3Connection,
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="online-section-3__icons-container">
                <Link
                  to="/kontakt/"
                  target="_blank"
                  rel="noreferrer nofollow"
                  className="online-section-3__single-icon online-section-3__single-icon--email"
                >
                  <img
                    src={require("assets/icons/email-big.svg").default}
                    alt=""
                  />
                </Link>
              </div>
            </div>
            <div className="col-9">
              <div
                className="online-section-3__text"
                dangerouslySetInnerHTML={{
                  __html: pageData.section3Connection2,
                }}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="online-section-4">
        <div className="container">
          <h2 className="section-title">{pageData.section4Title}</h2>
          <Divider marginBottom={45} marginTop={15} />
          <p
            className="online-section-4__textg"
            dangerouslySetInnerHTML={{ __html: pageData.section4Text }}
          />
          <h3 className="online-section-4__subtitle">
            {pageData.section4Heading}
          </h3>
          <div dangerouslySetInnerHTML={{ __html: pageData.section4List }} />
          <p>
            <strong>{pageData.section4Invitation}</strong>
          </p>
        </div>
      </section>
      <section className="online-section-5">
        <div className="container">
          <h2 className="section-title">{pageData.section5Title}</h2>
          <Divider marginBottom={45} marginTop={15} />
          <div className="row">
            <div className="col-lg-4">
              <ContactTile
                thumbnail={pageData.section5Block[0]?.section5Img?.sourceUrl}
                text={pageData.section5Block[0].section5Content}
                title={pageData.section5Block[0].section5Pros}
                buttonType="bookVisit"
              />
            </div>
            <div className="col-lg-4">
              <ContactTile
                thumbnail={pageData.section5Block[1]?.section5Img?.sourceUrl}
                text={pageData.section5Block[1].section5Content}
                title={pageData.section5Block[1].section5Pros}
                buttonType="phone"
              />
            </div>
            <div className="col-lg-4">
              <ContactTile
                thumbnail={pageData.section5Block[2]?.section5Img?.sourceUrl}
                text={pageData.section5Block[2].section5Content}
                title={pageData.section5Block[2].section5Pros}
              />
            </div>
          </div>
          <div className="online-buttons">
            <Link to="/rezerwacja-wizyt/" className="button button--green">
              Umów wizytę
            </Link>
            <button
              onClick={() => setBookingModal(true)}
              className="button button--red"
            >
              Zadzwoń
            </button>
            <Link to="/kontakt/" className="button button--orange">
              Wyślij zapytanie
            </Link>
          </div>
        </div>
      </section>
      <section className="online-section-6">
        <div className="container">
          <div
            className="post-main__content"
            dangerouslySetInnerHTML={{ __html: pageData.section6Content }}
          />
        </div>
      </section>
      {bookingModal && <BookingModal setBookingModal={setBookingModal} />}
    </Layout>
  );
};

export default OnlinePage;
