import "./contact-tile.scss";

import React from "react";
import Divider from "components/Divider";

const ContactTile = ({ title, thumbnail, text }) => {
  return (
    <div className="contact-tile">
      <div
        className="contact-tile__thumbnail"
        style={{ backgroundImage: `url(${thumbnail})` }}
      />
      <div className="contact-tile__title">{title}</div>
      <div className="contact-tile__text">{text}</div>
      <Divider marginBottom={45} marginTop={50} />
    </div>
  );
};

export default ContactTile;
